import request from '@/utils/request'

// 查询头像制作管理列表
export function listToolHead(query) {
  return request({
    url: '/work/ToolHead/list',
    method: 'get',
    params: query
  })
}

// 查询头像制作管理详细
export function getToolHead(id) {
  return request({
    url: '/work/ToolHead/' + id,
    method: 'get'
  })
}

// 新增头像制作管理
export function addToolHead(data) {
  return request({
    url: '/work/ToolHead',
    method: 'post',
    data: data
  })
}

// 修改头像制作管理
export function updateToolHead(data) {
  return request({
    url: '/work/ToolHead/update',
    method: 'post',
    data: data
  })
}

// 删除头像制作管理
export function delToolHead(id) {
  return request({
    url: '/work/ToolHead/remove/' + id,
    method: 'get'
  })
}

// 导出头像制作管理
export function exportToolHead(query) {
  return request({
    url: '/work/ToolHead/export',
    method: 'get',
    params: query
  })
}